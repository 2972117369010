import React from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import InitAnimation from './init-animation';
import Style from '../styles/common/footer.module.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.transition = props.transition;
    this.initAnimation = new InitAnimation();
  }

  contactAnimation = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingContact = document.querySelector('.init_a_headingContact');
      const introContact = document.querySelector('.init_a_introContact');
      this.initAnimation.heading(headingContact);
      this.initAnimation.intro(introContact);
      unobserve();
    }
  };

  render() {
    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <footer className={`footer ${Style.footer}`}>
        <Observer {...options} onChange={this.contactAnimation}>
          <section className={`section contact ${Style.contact}`}>
            <h2 className="heading headingReverse">
              <span className="text">
                Contact
              </span>
              <span className="clip init_a_headingContact">
                Contact
              </span>
            </h2>
            <p className={`intro init_a_introContact ${Style.secIntro}`}>
              お仕事の依頼やご相談、その他のご質問・ご意見などに関しては
              <br className="pcDisBlock" />
              こちらからお問い合わせください。
            </p>
            <p className={`btn btnReverse ${Style.btn}`}>
              <a href="mailto:info@bankto.co.jp"><span>Contact us</span></a>
            </p>
          </section>
        </Observer>
        <div className={Style.block}>
          <div className={Style.sitemap}>
            <section className={`section ${Style.content}`}>
              <div className={`inner ${Style.inner}`}>
                <div className={Style.left}>
                  <div className={Style.sec}>
                    <TransitionLink className={Style.heading} to="/services" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                      SERVICES
                      <div className={`viewMore ${Style.viewMore}`}>
                        <svg
                          className="arrow"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 125 20.4"
                        >
                          <line x1="0" y1="10.2" x2="150" y2="10.2" />
                          <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                        </svg>
                      </div>
                    </TransitionLink>
                    <ul className={Style.list}>
                      <li className={Style.item}>
                        <TransitionLink className={Style.heading} to="/services/strategy" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          Strategy
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink className={Style.heading} to="/services/contents" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          Contents
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink className={Style.heading} to="/services/design" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          Design
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink className={Style.heading} to="/services/engineering" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          Engineering
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink className={Style.heading} to="/services/mediagrowth" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          MediaGrowth
                        </TransitionLink>
                      </li>
                    </ul>
                  </div>
                  <div className={Style.sec}>
                    <TransitionLink className={Style.heading} to="/ourteam" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                      TEAM
                      <div className={`viewMore ${Style.viewMore}`}>
                        <svg
                          className="arrow"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 125 20.4"
                        >
                          <line x1="0" y1="10.2" x2="150" y2="10.2" />
                          <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                        </svg>
                      </div>
                    </TransitionLink>
                    <ul className={Style.list}>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        光川貴浩
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        上田広樹
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        金原由佳
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        太田孟
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        人見和真
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        吉田健人
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        長谷川茉由
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        河井冬穂
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        早志祐美
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        猿田真維
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        寺田智嗣
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        若林成実
                        {/*</TransitionLink>*/}
                      </li>
                      <li className={Style.item}>
                        {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                        窪田令亜
                        {/*</TransitionLink>*/}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={Style.right}>
                  <div className={Style.sec}>
                    <TransitionLink className={Style.heading} to="/works" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                      WORKS
                      <div className={`viewMore ${Style.viewMore}`}>
                        <svg
                          className="arrow"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 125 20.4"
                        >
                          <line x1="0" y1="10.2" x2="150" y2="10.2" />
                          <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                        </svg>
                      </div>
                    </TransitionLink>
                    <ul className={Style.list}>
                      <li className={Style.item}>
                        <TransitionLink to="/works/pomagazine" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          ポmagazine
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/art-walk-kyoto" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          ART WALK KYOTO
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/do-ya" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          do-ya?
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/savvy" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          SAVVY.jp
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/seesaw" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          シーソー
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/food-design" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          フードデザイン<br/>未来の食を探るデザインリサーチ
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/kyotocity-kyocera-museum" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          京都市京セラ美術館
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>
                        <TransitionLink to="/works/kex" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                          KYOTO EXPERIMENT
                        </TransitionLink>
                      </li>
                      <li className={Style.item}>and more...</li>
                    </ul>
                  </div>
                  <div>
                    <div className={Style.secBtn}>
                      <TransitionLink className={Style.heading} to="/culture" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                        CULTURE
                        <div className={`viewMore ${Style.viewMore}`}>
                          <svg
                            className="arrow"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 125 20.4"
                          >
                            <line x1="0" y1="10.2" x2="150" y2="10.2" />
                            <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                          </svg>
                        </div>
                      </TransitionLink>
                    </div>
                    <div className={Style.secBtn}>
                      <TransitionLink className={Style.heading} to="/about" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                        ABOUT
                        <div className={`viewMore ${Style.viewMore}`}>
                          <svg
                            className="arrow"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 125 20.4"
                          >
                            <line x1="0" y1="10.2" x2="150" y2="10.2" />
                            <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                          </svg>
                        </div>
                      </TransitionLink>
                    </div>
                    {/*<div className={Style.secBtn}>*/}
                    {/*  <TransitionLink className={Style.heading} to="/recruit" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
                    {/*    RECRUIT*/}
                    {/*    <div className={`viewMore ${Style.viewMore}`}>*/}
                    {/*      <svg*/}
                    {/*        className="arrow"*/}
                    {/*        version="1.1"*/}
                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                    {/*        viewBox="0 0 125 20.4"*/}
                    {/*      >*/}
                    {/*        <line x1="0" y1="10.2" x2="150" y2="10.2" />*/}
                    {/*        <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />*/}
                    {/*      </svg>*/}
                    {/*    </div>*/}
                    {/*  </TransitionLink>*/}
                    {/*</div>*/}
                    <div className={Style.secBtn}>
                      <a
                        className={Style.heading}
                        href="mailto:info@bankto.co.jp"
                      >
                        CONTACT
                        <div className={`viewMore ${Style.viewMore}`}>
                          <svg
                            className="arrow"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 125 20.4"
                          >
                            <line x1="0" y1="10.2" x2="150" y2="10.2" />
                            <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className={Style.left}>
            <p className={Style.logo}>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 116 24.2"
              >
                <path d="M9.4,8.1c-2,0-3.4,0.5-4.7,1.8H4.7V0.1L4.7,0H0.1L0,0.1v23.7l0.1,0.1h2.7l0.1-0.1l1-1.3h0.1c1.3,1.2,2.9,1.7,4.7,1.7c4.5,0,8.1-3.5,8.1-8.2C16.7,11.8,13.8,8.1,9.4,8.1 M8.1,20.1c-2.4,0-3.7-1.6-3.7-3.9c0-2.3,1.3-3.9,3.7-3.9c2.4,0,3.9,1.6,3.9,3.9C12.1,18.5,10.5,20.1,8.1,20.1" />
                <path d="M27.8,8.1c-2.3,0-5.1,0.7-6.7,2.2v0.1L23,13h0.1c1.1-0.9,3-1.4,4.3-1.4c1.6,0,2.8,0.8,2.8,2.5c-3.9,0-10.4,0.1-10.4,5.4c0,3.1,2.6,4.7,5.8,4.7c2,0,3.6-0.8,4.5-1.7h0.1l0.9,1.3l0.1,0.1h3.2l0.1-0.1v-7.7C34.5,10.9,33.3,8.1,27.8,8.1 M30.1,18.1c-0.2,1.7-1.9,3-3.6,3c-1,0-2.2-0.5-2.2-1.7c0-2.1,3.2-2.2,4.7-2.2h1.1l0.1,0.1C30.2,17.2,30.2,17.5,30.1,18.1" />
                <path d="M39.7,8.6L39.7,8.6l4.5-0.1l0.1,0.1v1.9h0.1c0.6-1.2,2.2-2.3,4.5-2.3c4.8,0,5.7,3.2,5.7,7.3v8.4l-0.1,0.1h-4.6l-0.1-0.1v-7.4c0-1.8,0-4-2.5-4c-2.5,0-2.8,2.1-2.8,4v7.4l-0.1,0.1h-4.6l-0.1-0.1V8.6z" />
                <polygon points="59.6,0.1 59.7,0 64.3,0 64.4,0.1 64.4,14.1 64.4,14.1 68.3,8.6 68.4,8.5 73.5,8.5 73.5,8.6 68.8,15.4 68.8,15.5 74.5,23.8 74.5,23.9 69.1,23.9 69,23.8 64.4,16.9 64.4,16.9 64.4,23.8 64.3,23.9 59.7,23.9 59.6,23.8" />
                <path d="M116,11.6l-2.2-1.8l-0.2,0c-0.4,0.2-0.7,0.5-1.2,0.6c-1.3-1.5-3.3-2.3-5.7-2.3c-4.7,0-9.1,3.2-10.2,8c-1.1,4.9,2.1,8,6.8,8c4.7,0,9.1-3.2,10.2-8c0.2-1,0.3-2,0.2-2.8c0.6-0.3,1.2-0.7,1.7-1.1c0.2-0.2,0.4-0.4,0.6-0.6L116,11.6zM108.8,16.2c-0.5,2.3-2.5,4-4.8,4c-2.4,0-3.3-1.7-2.8-4c0.5-2.3,2.5-4,4.8-4C108.4,12.1,109.4,13.9,108.8,16.2" />
                <path d="M91,19.2c-0.5,0.4-1.1,1-1.8,1.3c-0.5,0.2-0.9,0.2-1.1,0.1c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.3,0.5-1.9,0.5-1.9l1.2-4h4l0.1-0.1l1.2-3.4l-0.1-0.1h-4.1l1.2-4l-0.1-0.1h-4.2l-0.1,0.1l-1.3,4h-2.4l-0.1,0.1l-1.1,3.4l0.1,0.1h2.3l-1,2.9c-0.5,1.7-0.7,2.3-0.8,3.1c-0.2,1.5,0.4,2.6,1.3,3.3c0.7,0.5,1.7,1,3.1,1c2.3,0,3.9-1.5,4.9-2.5c0.2-0.2,0.4-0.4,0.5-0.5l0-0.1l-1.9-1.9H91z" />
              </svg>
            </p>
            <p className={Style.address}>
              〒604-8261
              <br />
              京都市中京区式阿弥町130
              <br />
              SHIKIAMI CONCON No.10（2F）
              <br />
              T 075 252 2050
              <br />
              F 075 320 1344
            </p>
            <div className={Style.sns}>
              <ul className={Style.list}>
                {/* <li className={Style.item}>
                  <a
                    href="https://www.facebook.com/banktollc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="facebook"
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 60 60"
                    >
                      <circle cx="30" cy="30" r="30" />
                      <path d="M29.2,16.4C29.2,16.4,29.2,16.4,29.2,16.4c-0.2,0.1-0.3,0.2-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0,0,0.1-0.1,0.1c-0.5,1-0.8,2.2-0.8,3.6V26h-4.6v5.3h4.6v0v0V45h0h5.5v0V31.3h0h0h4.6l0.7-5.3l-0.5,0h-4.7v0v0v-3.4c0-1.5,0.4-2.6,2.6-2.6h2.8v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2C31.9,15,30.4,15.5,29.2,16.4z" />
                    </svg>
                  </a>
                </li> */}
                <li className={Style.item}>
                  <a
                    href="https://www.instagram.com/banktollc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="instagram"
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 60 60"
                    >
                      <circle cx="30" cy="30" r="30" />
                      <path d="M23.3,15c-4.6,0-8.3,3.7-8.3,8.3v13.3c0,4.6,3.7,8.3,8.3,8.3h13.3c4.6,0,8.3-3.7,8.3-8.3V23.3c0-4.6-3.7-8.3-8.3-8.3H23.3zM40,18.3c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7S39.1,18.3,40,18.3z M30,21.7c4.6,0,8.3,3.7,8.3,8.3s-3.7,8.3-8.3,8.3s-8.3-3.7-8.3-8.3S25.4,21.7,30,21.7z M30,25c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S32.8,25,30,25z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <p className={Style.copyright}><small>&copy; 2016 - bank to LLC.</small></p>
            <p className={Style.credit}><small>Illustration Kensuke Okano</small></p>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  transition: PropTypes.object.isRequired,
};

export default Footer;
